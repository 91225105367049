import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import common from './assets/css/common.css'
import ElementPlus, { imageEmits } from 'element-plus'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import AOS from 'aos'
import '../node_modules/aos/dist/aos.css'
import VWave from 'v-wave'
import VueAMap from 'vue-amap'
// fortawesome
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//NutUI
import NutUI from "@nutui/nutui";
// 采用按需加载时  此全局css样式，需要删除
import "@nutui/nutui/dist/style.css";
library.add(fas)
//滚动插件
import scroll from 'vue-seamless-scroll'


// 引入全局可用阿里图标样式文件
import './assets/fonts/iconfont.css'
// 引入全局可用阿里图标js文件
import './assets/fonts/iconfont.js'



createApp(App)
  .use(store)
  .use(router)
  .use(common)
  .use(ElementPlus)
  .use(NutUI)
  .use(VueAMap)
  .use(AOS)
  .use(scroll)
  .use(VWave)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
