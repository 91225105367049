import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

const _isMobile = function () {
  let flag = navigator.userAgent.match(
    /(phone|pod|iPhone|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  console.log(flag);
  return flag;
};

const routes_pc = [
  {
    path: "/",
    name: "Home",
    meta: { pathName: "首页" },
    component: () => import("../views/Home/Index"),
  },
  {
    path: "/product-center",
    name: "ProductCenter",
    meta: { pathName: "产品中心" },
    component: () => import("../views/Product/ProductCenter"),
  },
  {
    path: "/product-view",
    name: "ProductView",
    meta: { pathName: "产品介绍" },
    component: () => import("../views/Product/ProductView"),
    children: [
      {
        path: "/Product-1",
        name: "Product-1",
        meta: { pathName: "博华病理管理系统" },
      },
      {
        path: "/Product-2",
        name: "Product-2",
        meta: { pathName: "放射信息管理系统" },
      },
      {
        path: "/Product-3",
        name: "Product-3",
        meta: { pathName: "超声质控管理信息系统" },
      },
      {
        path: "/Product-4",
        name: "Product-4",
        meta: { pathName: "医学影像信息系统" },
      },
      {
        path: "/Product-5",
        name: "Product-5",
        meta: { pathName: "医技预约平台" },
      },
      {
        path: "/Product-6",
        name: "Product-6",
        meta: { pathName: "博华云胶片" },
      },
    ],
  },
  {
    path: "/about",
    name: "AboutUs",
    meta: { pathName: "关于我们" },
    component: () => import("../views/AboutUs/Index"),
  },
  {
    path: "/login",
    name: "login",
    meta: { pathName: "员工天地" },
    component: () => import("../views/Login/Index.vue"),
  },
  {
    path: "/solution-view",
    name: "Solution",
    meta: { pathName: "解决方案" },
    component: () => import("../views/Solution/Index"),
  },
  {
    path: "/Storage",
    name: "file",
    meta: { pathName: "文件管理" },
    component: () => import("../views/Storage/Index.vue"),
  },
  {
    path: "/news",
    name: "News",
    meta: { pathName: "医疗要闻" },
    component: () => import("../views/News/Index"),
  },
  {
    path: "/doc",
    name: "doc",
    meta: { pathName: "编辑" },
    component: () => import("../views/News/components/Edit"),
  },
];
const routes_mobile = [
  {
    path: "/",
    name: "Home",
    meta: { pathName: "首页" },
    component: () => import("../views/Mobile/Home/Index"),
  },
  // {
  //   path: '/product-center',
  //   name: 'ProductCenter',
  //   meta: { pathName: '产品中心' },
  //   component: () => import('../views/Mobile/Product/Index'),
  // },
  {
    path: "/product-view",
    name: "ProductView",
    meta: { pathName: "产品介绍" },
    component: () => import("../views/Mobile/Product/Index"),
    children: [
      {
        path: "/120220922001",
        name: "Product-1",
        meta: { pathName: "博华病理管理系统" },
      },
    ],
  },

  {
    path: "/about",
    name: "AboutUs",
    meta: { pathName: "关于我们" },
    component: () => import("../views/Mobile/AboutUs/Index"),
  },
  {
    path: "/login",
    name: "login",
    meta: { pathName: "员工天地" },
    component: () => import("../views/Login/Index.vue"),
  },
  {
    path: "/solution-view",
    name: "Solution",
    meta: { pathName: "解决方案" },
    component: () => import("../views/Mobile/Solution/Index"),
    children: [
      {
        path: "/220220922001",
        name: "Solution-1",
        meta: { pathName: "解决方案1" },
      },
    ],
  },
  {
    path: "/Storage",
    name: "file",
    meta: { pathName: "文件管理" },
    component: () => import("../views/Storage/Index.vue"),
  },
  {
    path: "/news",
    name: "News",
    meta: { pathName: "医疗要闻" },
    component: () => import("../views/Mobile/News/Index"),
  },
  // {
  //   path: '/doc',
  //   name: 'doc',
  //   meta: { pathName: '编辑' },
  //   component: () => import('../views/Mobile/News/components/Edit'),
  // },
];

const router = createRouter({
  history: createWebHashHistory("/bh_website/"),
  routes: _isMobile() ? routes_mobile : routes_pc,
  base: "/bh_website/",
});




export default router;
