<template>
    <router-view/>
</template>

<script>
export default {
name:"App",
  methods:{
    _isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    }
  },
  mounted(){
    if (this._isMobile()) {

    } else {

    }
  }
}
</script>
<style lang="scss">

</style>
