import { createStore } from 'vuex'
import persistedState from 'vuex-persistedstate'

export default createStore({
  state() {
    return {
      NavState: false,
      Key: '0',
      Menus: [
        { label: '首页', path: '/' },
        { label: '产品中心', path: '/product-view'},
        { label: '解决方案', path: '/solution-view'},
        { label: '医疗要闻', path: '/news' },
        { label: '关于我们', path: '/about'},
        { label: '员工天地', path: '/login'}
      ],
      DropdownItem: [
        {
          index: 0,
          label: '智慧医疗',
          path: '/',
          children: [
            {
              Id: '120220922001',
              label: '博华病理管理系统',
              path: 'Product-1',
              icon: 'heartbeat',
              img:require("../assets/img/HomeImg/BHRISmini.png")
            },
            {
              Id: '120220922002',
              label: '放射信息管理系统',
              path: 'Product-2',
              icon: 'laptop-medical',
              img:require("../assets/img/HomeImg/BHRISmini.png")
            },
            {
              Id: '120220922003',
              label: '超声质控管理信息系统',
              path: 'Product-3',
              icon: 'laptop-medical',
              img:require("../assets/img/HomeImg/BHRISmini.png")
            },
            {
              Id: '120220922004',
              label: '医学影像信息系统',
              path: 'Product-4',
              icon: 'procedures',
              img:require("../assets/img/HomeImg/BHRISmini.png")
            },
            {
              Id: '120220922005',
              label: '医技预约平台',
              path: 'Product-5',
              icon: 'procedures',
              img:require("../assets/img/HomeImg/BHRISmini.png")
            },
            {
              Id: '120220922006',
              label: '博华云胶片',
              path: 'Product-6',
              icon: 'plus-square',
              img:require("../assets/img/HomeImg/BHRISmini.png")
            },
          ],
        },

      ],
      Item: [],
      Doc: [],
      ItemId: '',
    }
  },
  getters: {},
  mutations: {
    GetItem(state, ItemId) {
      state.ItemId = ItemId
      console.log('StateItem', state.ItemId)
    },
    GetKey(state, Key) {
      state.Key = Key
      state.NavState = !state.NavState
      // console.log('StateKey', state.Key)
      // console.log(' state.NavState', state.NavState)
    },
    GetDoc(state, doc) {
      state.Doc = []
      state.Doc = doc
    },
    GetBannerImg(state, BannerImgIndex) {
      state.BannerImgIndex = ''
      state.BannerImgIndex = BannerImgIndex
    },
  },
  actions: {},
  modules: {},
  plugins: [persistedState({ storage: window.sessionStorage })],
})
